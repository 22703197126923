<template>
  <b-card no-body class="card-custom m-5 p-5 w-lg-550px" header-tag="header">
    <!--begin::Header-->
    <b-card-header class="card-header px-2">
      <div class="card-title align-items-start flex-column">
        <h2 class="card-label font-weight-bolder text-dark">SMARTID Account</h2>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Fill-in your account information</span
        >
      </div>
    </b-card-header>
    <!--end::Header-->
    <b-card-body class="d-flex flex-column">
      <h6 class="mb-10 text-dark">
        Create your SmartId Account. Fill in the required information.
      </h6>

      <!--begin::Form-->
      <b-form ref="employee-verification-form">
        <!--begin::Body-->
        <b-form-group label="Name" label-cols-lg="2" label-cols-xl="2">
          <b-form-input
            class="form-control-solid py-5 px-6"
            size="lg"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-0-live-feedback"
            placeholder="Name"
          ></b-form-input>
          <!-- end: Name Input -->

          <b-form-invalid-feedback id="input-0-live-feedback">
            Name is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-cols-lg="2" label-cols-xl="2" label="Email">
          <b-form-input
            class="form-control form-control-solid py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            size="lg"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            placeholder="Email address"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Password" label-cols-lg="2" label-cols-xl="2">
          <b-form-input
            class="form-control form-control-solid py-5 px-6"
            type="password"
            size="lg"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="Password"
          ></b-form-input>

          <b-form-invalid-feedback v-if="!$v.form.password.required">
            Password is required!
          </b-form-invalid-feedback>

          <b-form-invalid-feedback v-if="!$v.form.password.minLength">
            Password must be at least 6 characters!
          </b-form-invalid-feedback>
        </b-form-group>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </b-card-body>
    <b-card-footer footer-class="ml-auto mt-auto border-0">
      <b-button
        ref="submit-button"
        class="mr-2"
        type="submit"
        variant="primary"
        v-on:click="registerEmployee"
      >
        Create
      </b-button>
      <b-button class="mr-2" variant="light" v-on:click="cancelRegistration">
        Cancel
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import { email, minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import swal from "sweetalert";
import { mapState } from "vuex";

export default {
  name: "EmployeeVerificationForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * @param error
     */
    validationError(error) {
      const val = error.response.data.errors;
      const h = this.$createElement;
      const password = val["password"]
        ? val["password"].map((id) => {
            return h("p", { class: ["text-center"] }, id);
          })
        : [h("p", { class: ["text-center"] })];
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("p", { class: ["text-center"] }, [val["email"]]),
        ...password,
      ]);
      this.$bvModal.msgBoxOk(messageVNode, {
        title: "Error On Registration",
        centered: true,
        okVariant: "danger",
      });
    },

    registerEmployee() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["submit-button"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.form.verification_code = this.code;
      this.form.password_confirmation = this.form.password;
      this.$store
        .dispatch("submitEmployeeVerification", this.form)
        .then(() => {
          swal({
            title: "Registration successful",
            text: "Please Login using email and password",
            icon: "success",
          });
          this.$router.push("/login");
        })
        .catch((error) => {
          // Handles other than validation errors.
          if (!error.response.data.errors)
            swal("Registration Failed", "Server Error detected.", "error");
          else {
            this.validationError(error);
          }
        })
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
    cancelRegistration() {
      this.$router.push({
        path: "/verification/employee",
        query: { code: this.code },
      });
    },
  },
  computed: {
    ...mapState({
      code: (state) =>
        state.AuthModule.EmployeeVerification.VerificationInit.verificationCode,
    }),
    errors() {
      return this.$store.state.Auth.errors;
    },
  },
};
</script>

<style scoped></style>
